import { boot } from 'quasar/wrappers'
import { Notify } from 'quasar'

export default boot(async (/* { app, router, ... } */) => {
  Notify.registerType(
    'positive',
    {
      icon: 'far fa-check-circle',
      classes: 'positive-notification',
      group: 'positive',
      timeout: 3500,
      position: 'bottom'
    }
  )
  Notify.registerType(
    'info',
    {
      icon: 'fas fa-info-circle',
      classes: 'info-notification',
      group: 'info',
      timeout: 3500,
      position: 'bottom'
    }
  )
  Notify.registerType(
    'warning',
    {
      icon: 'fas fa-exclamation-triangle',
      classes: 'warning-notification',
      group: 'warning',
      timeout: 3500,
      position: 'bottom'
    }
  )
  Notify.registerType(
    'negative',
    {
      icon: 'far fa-times-circle',
      classes: 'negative-notification',
      group: 'negative',
      timeout: 3500,
      position: 'bottom'
    }
  )
})
