
export const allRoutes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout'),
    children: [
      { path: '', name: 'Főoldal', props: true, component: () => import('../pages/Home') },
      { path: '/:catchAll(.*)*', name: 'Error', component: () => import('../pages/MissingUrl.vue') }
    ]
  }
]
export default allRoutes
