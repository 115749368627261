/* eslint-disable no-console */
/* eslint-disable no-void */
/* eslint-disable no-underscore-dangle */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 */

import { createSSRApp, createApp } from 'vue';





import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';

import '@quasar/extras/line-awesome/line-awesome.css';

import '@quasar/extras/material-icons/material-icons.css';





// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass';





import 'src/css/app.sass';



import { createQuasarApp, ssrIsRunningOnClientPWA } from './app';
// eslint-disable-next-line import/extensions
import quasarUserOptions from './quasar-user-options';









const publicPath = `/`;


async function start({
  app,
  router,
  
  
}, bootFiles) {
  

  
  let hasRedirected = false;
  const getRedirectUrl = (url) => {
    try {
      
      return router.resolve(url).href;

      
    } catch (err) {
      // continue regardless of error
    }

    return Object(url) === url
      ? null
      : url;
  };
  const redirect = (url) => {
    hasRedirected = true;

    if (typeof url === 'string' && /^https?:\/\//.test(url)) {
      window.location.href = url;
      return;
    }

    const href = getRedirectUrl(url);

    // continue if we didn't fail to resolve the url
    if (href !== null) {
      window.location.href = href;
    }
  };

  const urlPath = window.location.href.replace(window.location.origin, '');

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i += 1) {
    try {
      await bootFiles[i]({
        app,
        router,
        
        ssrContext: null,
        redirect,
        urlPath,
        publicPath,
      });
    } catch (err) {
      if (err && err.url) {
        redirect(err.url);
        return;
      }
      console.error('[Quasar] boot error:', err);
      return;
    }
  }
  if (hasRedirected === true) {
    return;
  }
  
  app.use(router);
  
  if (ssrIsRunningOnClientPWA === true) {
    
    app.mount('#q-app');
  } else {
    // wait until router has resolved all async before hooks
    // and async components...
    router.isReady().then(() => {
      
      app.mount('#q-app');
    });
  }
}

createQuasarApp(ssrIsRunningOnClientPWA ? createApp : createSSRApp, quasarUserOptions)

  .then((app) => {
    return Promise.allSettled([
      
      import(/* webpackMode: "eager" */ 'boot/meteor'),
      
      import(/* webpackMode: "eager" */ 'boot/errorHandler'),
      
      import(/* webpackMode: "eager" */ 'boot/notify-defaults'),
      
      import(/* webpackMode: "eager" */ 'quasar-app-extension-ssg/src/boot/ssg-corrections'),
      
    ]).then((bootFiles) => {
      const boot = bootFiles
        .map((result) => {
          if (result.status === 'rejected') {
            console.error('[Quasar] boot error:', result.reason);
            return undefined;
          }
          return result.value.default;
        })
        .filter((entry) => typeof entry === 'function');

      start(app, boot);
    });
  });

