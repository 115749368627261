import { server } from 'boot/meteor'

export default async ({ app, router }) => {
  app.config.errorHandler = function (err, vm, info) {
    if (process.env.SERVER) { return }
    const lastError = localStorage.getItem('LAST_FRONTEND_ERROR')
    console.log('ERROR', err)
    const oneMinAgo = new Date(Number(lastError))
    oneMinAgo.setTime(oneMinAgo.getTime() + 1000 * 60)
    if (!lastError || new Date() > oneMinAgo) {
      if (err.reason && err.reason.reason === "You've been logged out by the server. Please log in again.") {
        return
      } else {
        server.call('saveFrontendError', { url: router.currentRoute.fullPath, error: JSON.stringify(err, Object.getOwnPropertyNames(err)) })
      }
      localStorage.setItem('LAST_FRONTEND_ERROR', String(new Date().getTime()))
    } else {
      console.log('NOT SENDING FRONTEND ERROR FOR ONE MINUTE')
    }
  }
}

export { server }
