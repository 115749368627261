const SimpleDDP = require('simpleddp') // nodejs
const simpleDDPLogin = require('simpleddp-plugin-login').simpleDDPLogin
const ws = require('isomorphic-ws')
console.log('branch', process.env.CF_PAGES_BRANCH)
console.log('endpoint', process.env.CF_PAGES_BRANCH === 'main' ? 'wss://admin.puregoldprotein.com/websocket' : (process.env.CF_PAGES_BRANCH === 'development' ? 'wss://admin.roseprotein.com/websocket' : 'ws://localhost:4000/websocket'))
const server = new SimpleDDP({
  endpoint: process.env.CF_PAGES_BRANCH === 'main' ? 'wss://admin.puregoldprotein.com/websocket' : (process.env.CF_PAGES_BRANCH === 'development' ? 'wss://admin.roseprotein.com/websocket' : 'ws://localhost:4000/websocket'),
  SocketConstructor: ws,
  reconnectInterval: 5000
}, [simpleDDPLogin])

export default async ({ app }) => {
  await server.connect()
  app.$server = server
}

export { server }
